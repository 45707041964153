import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './NovaAnalise.css';

const NovaAnalise = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    responsavel: '',
    identificacaoAmostra: '',
    dataColeta: '',
    geolocalizacao: '',
    tipoAmostra: '',
    tipoAnalise: '',
    armazenamento: '',
    preTratamento: '',
    volume: '',
    massa: '',
    aspecto: '',
    foto: null,
    observacoes: '',
    aplicacaoResultado: '',
  });

  const [choice, setChoice] = useState(null);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [newGroupName, setNewGroupName] = useState('');

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user || !user.uid) {
      Swal.fire('Erro', 'Usuário não autenticado.', 'error');
      return;
    }

    const data = new FormData();
    for (let key in formData) {
      data.append(key, formData[key]);
    }

    const identifier = selectedGroup ? parseInt(selectedGroup, 10) : 0;
    data.append('identifier', identifier);

    try {
      const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/users/${user.uid}/analises`;

      await axios.post(endpoint, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      Swal.fire({
        icon: 'success',
        title: 'Análise criada com sucesso!',
        text: 'Redirecionando...',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      }).then(() => {
        navigate('/minhas-analises');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao solicitar análise',
        text: error.response?.data || 'Tente novamente mais tarde.',
      });
    }
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/users/${user.uid}/groups`
        );
        setGroups(response.data);
      } catch (error) {
        console.error('Erro ao buscar grupos:', error);
      }
    };

    if (user?.uid) fetchGroups();
  }, [user]);

  const handleCreateGroup = async () => {
    if (!newGroupName.trim()) {
      Swal.fire('Erro', 'O nome do grupo não pode estar vazio!', 'error');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/${user.uid}/groups`,
        {
          name: newGroupName,
        }
      );

      const createdGroup = response.data;
      setGroups([...groups, createdGroup]);
      setSelectedGroup(createdGroup.identifier);
      setNewGroupName('');

      Swal.fire('Sucesso', 'Grupo criado com sucesso!', 'success');
    } catch (error) {
      Swal.fire('Erro', 'Erro ao criar grupo. Tente novamente.', 'error');
    }
  };

  if (choice === null) {
    return (
      <div className="nova-analise-container">
        <h1>O que deseja fazer?</h1>
        <div className="choice-buttons">
          <button onClick={() => setChoice('unitaria')} className="choice-btn">
            Solicitar Análise Unitária
          </button>
          <button onClick={() => setChoice('grupo')} className="choice-btn">
            Iniciar ou Adicionar em Grupo
          </button>
        </div>
        <button
          className="minhas-analises-btn"
          onClick={() => navigate('/minhas-analises')}
        >
          Minhas Análises
        </button>
      </div>
    );
  }

  if (choice === 'grupo' && !selectedGroup) {
    return (
      <div className="nova-analise-container">
        <h1>Gerenciar Grupos de Análises</h1>

        <div className="create-group">
          <h2>Criar Novo Grupo</h2>
          <input
            type="text"
            placeholder="Nome do Grupo"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
          <button className="create-group-btn" onClick={handleCreateGroup}>
            Criar Grupo
          </button>
        </div>

        <div className="existing-groups">
          <h2>Grupos Existentes</h2>
          {groups.length > 0 ? (
            <ul className="group-list">
              {groups.map((group) => (
                <li key={group.identifier} className="group-item">
                  <span>{group.name}</span>
                  <button
                    className="add-analysis-btn"
                    onClick={() => setSelectedGroup(group.identifier)}
                  >
                    Adicionar Análise
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>Nenhum grupo existente. Crie um novo grupo acima.</p>
          )}
        </div>

        <button
          className="minhas-analises-btn"
          onClick={() => navigate('/minhas-analises')}
        >
          Minhas Análises
        </button>
      </div>
    );
  }

  if (choice === 'unitaria' || (choice === 'grupo' && selectedGroup)) {
    const selectedGroupName = groups.find(group => group.identifier === selectedGroup)?.name || selectedGroup;

    return (
      <div className="nova-analise-container">
        <h1>
          {choice === 'unitaria'
            ? 'Solicitar Nova Análise'
            : `Adicionar Análise ao Grupo: ${selectedGroupName}`}
        </h1>
        <form className="nova-analise-form" onSubmit={handleSubmit}>
          <div className="form-content">
            <div className="form-group">
              <label>Nome do Responsável</label>
              <input
                type="text"
                name="responsavel"
                value={formData.responsavel}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Identificação da Amostra</label>
              <input
                type="text"
                name="identificacaoAmostra"
                value={formData.identificacaoAmostra}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Data de Coleta</label>
              <input
                type="date"
                name="dataColeta"
                value={formData.dataColeta}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Geolocalização</label>
              <input
                type="text"
                name="geolocalizacao"
                value={formData.geolocalizacao}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Tipo de Amostra</label>
              <select
                name="tipoAmostra"
                value={formData.tipoAmostra}
                onChange={handleChange}
                required
              >
                <option value="">Selecione</option>
                <option value="agua">Água</option>
                <option value="alimento">Alimento</option>
                <option value="tecido">Tecido Animal</option>
                <option value="solo">Solo</option>
              </select>
            </div>
            <div className="form-group">
              <label>Tipo de Análise</label>
              <input
                type="text"
                name="tipoAnalise"
                value={formData.tipoAnalise}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Armazenamento</label>
              <input
                type="text"
                name="armazenamento"
                value={formData.armazenamento}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Pré-tratamento</label>
              <input
                type="text"
                name="preTratamento"
                value={formData.preTratamento}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Volume</label>
              <input
                type="number"
                name="volume"
                value={formData.volume}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Massa</label>
              <input
                type="number"
                name="massa"
                value={formData.massa}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Aspecto da Amostra</label>
              <input
                type="text"
                name="aspecto"
                value={formData.aspecto}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Foto da Amostra</label>
              <input type="file" name="foto" onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Observações</label>
              <textarea
                name="observacoes"
                value={formData.observacoes}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="form-group">
              <label>Aplicação do Resultado (opcional)</label>
              <textarea
                name="aplicacaoResultado"
                value={formData.aplicacaoResultado}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type="submit" className="solicitar-btn">
              Solicitar Análise
            </button>
          </div>
        </form>
      </div>
    );
  }
};

export default NovaAnalise;
