import { useContext, createContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true); // Garante que o carregamento seja gerenciado corretamente
      if (currentUser) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users/${currentUser.uid}`);
          const userData = response.data;
          console.log('User data fetched:', userData);
          setUser({ ...currentUser, ...userData });
        } catch (error) {
          console.error('Erro ao buscar dados do usuário:', error);
          setUser(null); // Garante que o usuário seja deslogado se houver erro
        }
      } else {
        setUser(null); // Define usuário como null se não autenticado
      }
      setLoading(false); // Finaliza o carregamento
    });
  
    return unsubscribe; // Cleanup do listener
  }, []);
  

  return (
    <AuthContext.Provider value={{ user, setUser, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
