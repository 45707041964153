import React, { useState } from 'react';
import axios from 'axios';
import './Prompt.css';

const Prompt = ({ isOpen, onClose, onSave }) => {
  const [avatar, setAvatar] = useState(null);
  const [promptName, setPromptName] = useState('');
  const [promptCommand, setPromptCommand] = useState('');
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
  
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const maxSize = 300; // Define o tamanho máximo (300x300)
  
          let width = img.width;
          let height = img.height;
  
          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }
  
          canvas.width = width;
          canvas.height = height;
  
          ctx.drawImage(img, 0, 0, width, height);
          const resizedImage = canvas.toDataURL('image/jpeg', 0.8); // Comprime a imagem para 80% da qualidade
          setAvatar(resizedImage);
        };
      };
      reader.readAsDataURL(file);
    }
  };
  

  const handleSavePrompt = async () => {
    if (!promptName || !promptCommand) {
      alert('Por favor, preencha todos os campos.');
      return;
    }

    const newPrompt = { avatar, promptName, promptCommand };
    setLoading(true);

    try {
      const checkResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/prompts?promptName=${encodeURIComponent(promptName)}`
      );

      if (checkResponse.data.exists) {
        alert('Esse prompt já existe!');
        setLoading(false);
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/prompts`, newPrompt);
      onSave(response.data.prompt);
      onClose();
    } catch (error) {
      console.error('Erro ao salvar prompt:', error);
      alert('Erro ao salvar prompt.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="prompt-overlay" onClick={onClose}>
      <div className="prompt-modal" onClick={(e) => e.stopPropagation()}>
        <h2 className="prompt-title">Criar Novo Prompt</h2>

        <div className="prompt-avatar-section">
          {avatar && (
            <div className="avatar-preview">
              <img src={avatar} alt="Avatar Preview" />
            </div>
          )}
          <input
            type="file"
            accept="image/*"
            className="avatar-upload"
            onChange={handleImageUpload}
          />
        </div>

        <div className="prompt-input-group">
          <input
            type="text"
            placeholder="Nome do Prompt"
            value={promptName}
            onChange={(e) => setPromptName(e.target.value)}
            className="prompt-input"
          />
          <textarea
            placeholder="Comando do Prompt"
            value={promptCommand}
            onChange={(e) => setPromptCommand(e.target.value)}
            rows="4"
            className="prompt-textarea"
          />
        </div>

        <div className="prompt-buttons">
          <button onClick={handleSavePrompt} disabled={loading} className="save-button">
            {loading ? 'Salvando...' : 'Salvar'}
          </button>
          <button onClick={onClose} className="close-button">
            Fechar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Prompt;
