import React from 'react';
import './styles.css';
import logo from './logo/logo.png';

const Lp = () => {
  return (
    <div className="lphnn">
      <div className="logo-container">
        <a href={process.env.REACT_APP_HOME_URL}>
          <img src={logo} alt="Logo" />
        </a>
      </div>
      <div className="tent">
        {/* <h1>Welcome to LabConect!</h1> */}
      </div>
      <div className="login">
        <button onClick={() => window.location.href='/login'}>Start</button>
      </div>
    </div>
  );
};

export default Lp;
