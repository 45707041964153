import React from 'react';
// import Notifications from './Notifications/Notifications';
import Exit from './Notifications/Exit';
import CustomIcon from './Notifications/CustomIcon';
import './Nav.css';

const AdminNav = () => {
  return (
    <div className="nav-container">
      <CustomIcon />
      {/* <Notifications /> */}
      <Exit />
    </div>
  );
};

export default AdminNav;
