import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AnaliseFullByAdmin.css';
// import './AnaliseTecnicaModal.css';
import IAPanel from './IAPanel'; // Importa o painel de IA
import Swal from 'sweetalert2';
import FileUpload from './FileUpload';

const AnaliseFullByAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [analise, setAnalise] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [mensagem, setMensagem] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);

// DAQUI DAQUI DAQUI


const [status, setStatus] = useState('');
const [showEditorModal, setShowEditorModal] = useState(false); 
const [uploadedFiles, setUploadedFiles] = useState([]);
const [analysisText, setAnalysisText] = useState('');
const [isIaPanelOpen, setIaPanelOpen] = useState(false);

useEffect(() => {
  if (analise?.analysisData && analise.analysisData[0]?.status) {
    setStatus(analise.analysisData[0].status);
  }
}, [analise]);

useEffect(() => {
  if (analise?.status) {
    setStatus(analise.status); // Sincroniza o status inicial com o valor do banco de dados
  }

  if (analise?.technicalAnalysisReport) {
    setAnalysisText(analise.technicalAnalysisReport); // Sincroniza o texto da análise com o valor do banco de dados
  }
}, [analise]);



const handleClose = () => {
  setIsChatOpen(false); // Ou qualquer lógica para fechar o modal
};


// Função para abrir o editor de análise técnica
const handleOpenEditor = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${analise._id}/report`);
    setAnalysisText(response.data.technicalAnalysisReport || ''); // Atualiza o estado com o relatório técnico do banco de dados
    setShowEditorModal(true); // Abre o modal
  } catch (error) {
    console.error('Erro ao obter o relatório técnico:', error);
    Swal.fire({
      icon: 'error',
      title: 'Erro!',
      text: 'Erro ao obter relatório técnico.',
      confirmButtonText: 'Tentar novamente'
    });
  }
};
// Função para fechar o editor de análise técnica
const handleCloseEditor = () => setShowEditorModal(false);

// Função para salvar o status no backend ao alterar
const handleStatusChange = async (e) => {
  const newStatus = e.target.value;
  setStatus(newStatus); // Atualiza o estado localmente

  try {
    if (!analise._id) {
      console.error('ID da análise não disponível');
      return;
    }

    await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${analise._id}/status`, { status: newStatus });
    
    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      text: 'Status atualizado com sucesso!',
      confirmButtonText: 'OK'
    });
  } catch (error) {
    console.error('Erro ao atualizar status:', error);
    
    Swal.fire({
      icon: 'error',
      title: 'Erro!',
      text: 'Erro ao atualizar status.',
      confirmButtonText: 'Tentar novamente'
    });
  }
};

// Função para lidar com o upload de arquivos
const handleFileChange = (e) => {
  const files = Array.from(e.target.files);
  setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
};

const handleFileUpload = async () => {
  if (uploadedFiles.length === 0) {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Nenhum arquivo selecionado.',
      confirmButtonText: 'OK'
    });
    return;
  }

  const formData = new FormData();
  uploadedFiles.forEach((file) => {
    formData.append('document', file); // Nome do campo deve ser 'document' para corresponder à rota backend
  });

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${analise._id}/upload-document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      text: 'Arquivos enviados com sucesso!',
      confirmButtonText: 'OK'
    });

    // Atualiza os arquivos enviados com o retorno do backend (se necessário)
    setUploadedFiles([...uploadedFiles, ...response.data.files]);
  } catch (error) {
    console.error('Erro ao enviar arquivos:', error);
    
    Swal.fire({
      icon: 'error',
      title: 'Erro!',
      text: 'Erro ao enviar arquivos.',
      confirmButtonText: 'Tentar novamente'
    });
  }
};

// Função para salvar o relatório técnico no backend
const handleSaveAnalysisText = async () => {
  try {
    await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${analise._id}/report`, { technicalAnalysisReport: analysisText });

    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      text: 'Relatório técnico salvo com sucesso!',
      confirmButtonText: 'OK'
    });
  } catch (error) {
    console.error('Erro ao salvar relatório técnico:', error);
    
    Swal.fire({
      icon: 'error',
      title: 'Erro!',
      text: 'Erro ao salvar relatório técnico.',
      confirmButtonText: 'Tentar novamente'
    });
  }
};


//  ATÉ AQUI ATÉ AQUI 





  const toggleChatModal = () => {
    setIsChatOpen(!isChatOpen);
  };

  useEffect(() => {
    const fetchAnalise = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${id}`);
        setAnalise(response.data);
      } catch (err) {
        setError('Erro ao carregar a análise.');
      } finally {
        setLoading(false);
      }
    };

    const fetchChat = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${id}/chat`);
        setChatMessages(response.data);
      } catch (err) {
        console.error('Erro ao carregar o chat:', err);
      }
    };

    fetchAnalise();
    fetchChat();
  }, [id]);

  const handleSendMessage = async () => {
    if (mensagem.trim() === '') return;

    try {
      const newMessage = { sender: 'analista', message: mensagem };
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${id}/chat`, newMessage);
      setChatMessages(response.data);
      setMensagem('');
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    }
  };

  if (loading) return <div className="loading">Carregando...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div>
      
    
    <div className="analise-full-container">
      {/* Imagem de capa */}
      <div
        className="analise-cover"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL}/uploads/${analise?.coverPhoto || 'default.jpg'})`,
        }}
      ></div>

      {/* Container principal dividido */}
      <div className="analise-main-content">
        {/* Conteúdo da análise */}
        <div className="analise-content">
          {analise && (
            <>
              <div className="analise-left-column">
                <h1>{analise.title || 'Análise'}</h1>
                <p>
                  <strong>Responsável:</strong> {analise.analysisData[0]?.responsibleName || 'Não especificado'}
                </p>
                {analise.analysisData[0]?.samplePhoto && (
                  <img
                    className="analise-image"
                    src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${analise.analysisData[0].samplePhoto}`}
                    alt="Foto da Amostra"
                  />
                )}
              </div>
              <div className="analise-right-column">
                <p>
                  <strong>Tipo de Amostra:</strong> {analise.analysisData[0]?.sampleType || 'Não especificado'}
                </p>
                <p>
                  <strong>Data de Coleta:</strong>{' '}
                  {new Date(analise.analysisData[0]?.collectionDate).toLocaleDateString() || 'Não especificado'}
                </p>
                <p>
                  <strong>Geolocalização:</strong> {analise.analysisData[0]?.geolocation || 'Não especificada'}
                </p>
                <p>
                  <strong>Armazenamento:</strong> {analise.analysisData[0]?.storageType || 'Não especificado'}
                </p>
                <p>
                  <strong>Observações:</strong> {analise.analysisData[0]?.additionalObservations || 'Nenhuma observação'}
                </p>
                <p>
            <strong>Aplicação do Resultado:</strong>{' '}
            {analise?.analysisData[0]?.analysisApplication || 'Não especificado'}
          </p>
          <p>
            <strong>Pré-tratamento:</strong> {analise?.analysisData[0]?.preTreatment || 'Não especificado'}
          </p>
          <p>
            <strong>Volume ou Massa da Amostra:</strong>{' '}
            {analise?.analysisData[0]?.sampleVolumeOrMass || 'Não especificado'}
          </p>
          <p>
            <strong>Aspecto da Amostra:</strong> {analise?.analysisData[0]?.sampleAspect || 'Não especificado'}
          </p>
          <p>
            <strong>Identificação no Laboratório:</strong>{' '}
            {analise?.analysisData[0]?.labIdentification || 'Não especificado'}
          </p>
          <p>
            <strong>Descrição do Ensaio/Experimento:</strong>{' '}
            {analise?.analysisData[0]?.experimentDescription || 'Não especificado'}
          </p>
              </div>
            </>
          )}
        </div>

        {/* Informações adicionais */}
        <div className="additional-info">
          <h2>Informações Adicionais</h2>
          {analise?.analysisData[0]?.additionalInfo && analise.analysisData[0].additionalInfo.length > 0 ? (
            analise.analysisData[0].additionalInfo.map((info, index) => (
              <div key={index} className="additional-info-item">
                <h3>{info.title}</h3>
                <p>{info.content}</p>
              </div>
            ))
          ) : (
            <p>Não há informações adicionais disponíveis.</p>
          )}
        </div>
      </div>

      {/* Botão flutuante do chat */}
      <div className="chat-float-button" onClick={toggleChatModal}>
        💬
      </div>

      {/* Modal do chat */}
      <div className={`chat-modal ${isChatOpen ? 'active' : ''}`}>
        <div className="chat-modal-content">
          <button className="chat-modal-close" onClick={toggleChatModal}>
            ✕
          </button>
          <h2>Chat</h2>
          <div className="chat-messages">
            {chatMessages.map((msg, index) => (
              <div key={index} className={`chat-message ${msg.sender}`}>
                <p>{msg.message}</p>
                <span>
                  {new Date(msg.timestamp).toLocaleString('pt-BR', {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  })}
                </span>
              </div>
            ))}
          </div>
          <div className="chat-input">
            <input
              type="text"
              placeholder="Digite sua mensagem"
              value={mensagem}
              onChange={(e) => setMensagem(e.target.value)}
            />
            <button onClick={handleSendMessage}>Enviar</button>
          </div>
        </div>
      </div>

 
      </div>


      <div className='tecnico'>
          {/* < AnaliseTecnicaModal /> */}

          {/* /********************DAQUI ***************/}

      <div className="analise-modal" onClick={(e) => e.stopPropagation()}>
        <h2>Avaliação  Técnica</h2>

        {/* Seletor de Status */}
        <div className="status-section">
          <label htmlFor="status">Status da Análise:</label>
          <select id="status" value={status} onChange={handleStatusChange}>
            <option value="Em espera">Em espera</option>
            <option value="Em andamento">Em andamento</option>
            <option value="Concluída">Concluída</option>
          </select>
        </div>

        {/* Editor de Análise */}
        <div className="editor-section">
          <h3>Análise Técnica</h3>
          <button className="editor-button" onClick={handleOpenEditor}>
            Abrir Editor de Análise
          </button>
        </div>


      <FileUpload analiseId={analise?._id} />

        {/* Exportar Análise */}
        <div className="export-section">
          <h3>Exportar Análise</h3>
          <button className="export-button" onClick={() => Swal.fire({
            icon: 'info',
            title: 'Escolha',
            text: 'Escolha PDF ou DOCX',
            confirmButtonText: 'OK'
          })}>
            Exportar como PDF/DOCX
          </button>
        </div>

        {/* Painel de IA */}
        <div className="ia-panel-to-button">
        <button onClick={() => setIaPanelOpen(true)}>Abrir Painel de IA</button>


        </div>
          <IAPanel 
            isOpen={isIaPanelOpen} 
            handleClose={() => setIaPanelOpen(false)} 
            analiseId={analise?._id}
          />  
        {/* Editor de Análise Modal */}
        {showEditorModal && (
          <div className="editor-modal-overlay" onClick={handleCloseEditor}>
            <div className="editor-modal" onClick={(e) => e.stopPropagation()}>
              <h2>Análise Técnica</h2>
              <textarea
                value={analysisText}
                onChange={(e) => setAnalysisText(e.target.value)}
                placeholder="Escreva sua análise técnica aqui..."
                rows="20"
                cols="50"
              />
              <div className="modal-buttons">
                <button onClick={handleSaveAnalysisText}>Salvar Análise</button>
                <button onClick={handleCloseEditor}>Fechar</button>
              </div>
            </div>
          </div>
        )}

        {/* Botão de Fechar */}
        {/* <button onClick={handleClose} className="close-button">Fechar</button> */}
      </div>
    </div>

          {/* /********************ATÉ AQUI ***************/}


</div>
  );
};

export default AnaliseFullByAdmin;
