import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css';
import logo from '../img/logo.png';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/login`, { username, password });
      if (response.status === 200) {
        localStorage.setItem('adminAuthenticated', 'true');
        navigate('/admin'); // Redireciona para o painel do admin
      }
    } catch (err) {
      setError('Senha incorreta');
    }
  };

  return (
    <div className="admin-login-container">
      <div className="login-box">
        <img src={logo} alt="Logo" className="logo" />
        <h2 className="login-title">Login Administrativo</h2>
        <form onSubmit={handleLogin} className="login-form">
          <div className="input-group">
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Senha do Admin"
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="login-button">Entrar</button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
