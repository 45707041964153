// src/components/ProtectedAdminRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedAdminRoute = ({ children }) => {
  const adminAuthenticated = localStorage.getItem('adminAuthenticated'); 

  if (!adminAuthenticated) {
    return <Navigate to="/admin/login" />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default ProtectedAdminRoute;
