import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import './FileUpload.css';

const FileUpload = ({ analiseId }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showFilesModal, setShowFilesModal] = useState(false);

  // Função para buscar arquivos
  const fetchFiles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/analises/${analiseId}/files`
      );
      setUploadedFiles(response.data);
      console.log('Arquivos carregados:', response.data);
    } catch (error) {
      console.error('Erro ao buscar arquivos:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Erro ao buscar arquivos.',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleOpenFilesModal = async () => {
    await fetchFiles(); // Garante que a lista será atualizada
    setShowFilesModal(true); // Abre o modal após buscar os arquivos
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleFileUpload = async () => {
    if (uploadedFiles.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Nenhum arquivo selecionado.',
        confirmButtonText: 'OK',
      });
      return;
    }
  
    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append('document', file); // Certifique-se de que 'document' é o nome esperado
    });
  
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/analises/${analiseId}/upload-document`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
  
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Arquivos enviados com sucesso!',
        confirmButtonText: 'OK',
      });
  
      setUploadedFiles([]); // Limpa a lista de arquivos locais
      await fetchFiles(); // Atualiza a lista após o upload
    } catch (error) {
      console.error('Erro ao enviar arquivos:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Erro ao enviar arquivos.',
        confirmButtonText: 'Tentar novamente',
      });
    }
  };
  

  const handleDeleteFile = async (fileId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/analises/${analiseId}/files/${fileId}`
      );
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Arquivo excluído com sucesso!',
        confirmButtonText: 'OK',
      });

      setUploadedFiles((prevFiles) => prevFiles.filter((file) => file._id !== fileId));
    } catch (error) {
      console.error('Erro ao excluir arquivo:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Erro ao excluir arquivo.',
        confirmButtonText: 'Tentar novamente',
      });
    }
  };

  return (
    <div className="upload-section">
      <h3>Upload de Arquivos Relacionados</h3>
      <input type="file" onChange={handleFileChange} multiple />
      <button className="upload-button" onClick={handleFileUpload}>
        Enviar Arquivos
      </button>
      <button
        className="view-files-button"
        onClick={handleOpenFilesModal}
      >
        Ver Arquivos Enviados
      </button>

      {showFilesModal && (
        <div className="files-modal-overlay" onClick={() => setShowFilesModal(false)}>
          <div className="files-modal" onClick={(e) => e.stopPropagation()}>
            <h2>Arquivos Enviados</h2>
            <div className="uploaded-files-list">
              {uploadedFiles.length > 0 ? (
                uploadedFiles.map((file) => (
                  <div key={file._id} className="file-item">
                    <p>{file.fileName}</p>
                    <button className="buttonExcluir" onClick={() => handleDeleteFile(file._id)}>
                      Excluir
                    </button>
                  </div>
                ))
              ) : (
                <p>Nenhum arquivo enviado</p>
              )}
            </div>
            <button onClick={() => setShowFilesModal(false)}>Fechar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
