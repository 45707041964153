import React from 'react';
import { useNavigate } from 'react-router-dom'; // Redirecionamento

const Exit = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Limpa autenticação e redireciona para login admin
    localStorage.removeItem('adminAuthenticated'); // Ou qualquer estado de autenticação
    navigate('/admin/login'); // Redireciona para a página de login do admin
  };

  return (
    <div onClick={handleLogout} style={{ cursor: 'pointer' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style={{ fill: '#033256' }}>
        <path d="M2 12l5 4v-3h9v-2H7V8l-5 4z"></path>
        <path d="M13 3a8.931 8.931 0 0 0-6.364 2.636L8.05 7.05A6.961 6.961 0 0 1 13 5c1.878 0 3.628.729 4.95 2.05S20 10.12 20 12c0 1.878-.729 3.628-2.05 4.95-1.322 1.321-3.072 2.05-4.95 2.05s-3.628-.729-4.95-2.05l-1.414 1.414A8.938 8.938 0 0 0 13 21c2.403 0 4.665-.937 6.364-2.636C21.063 16.665 22 14.403 22 12s-.937-4.665-2.636-6.364A8.931 8.931 0 0 0 13 3z"></path>
      </svg>
    </div>
  );
};

export default Exit;
