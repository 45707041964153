import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';
import Swal from 'sweetalert2';

const ResetPassword = () => {
  const [email, setEmail] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      Swal.fire('Erro', 'Formato de e-mail inválido.', 'error');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      Swal.fire('Sucesso', 'E-mail de redefinição de senha enviado com sucesso.', 'success');
    } catch (error) {
      Swal.fire('Erro', error.message, 'error');
    }
  };

  return (
    <div className="sign-up-page">
      <div className="lph">
        <div className="lph-title">
          <h1>Redefinir Senha</h1>
        </div>
        <form className="login" onSubmit={handleResetPassword}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail"
            className="login-input"
          />
          <button type="submit">Enviar</button>
        </form>
        <div className="link-su">
          <Link to="/login" className="login-link">Ir para Login</Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
