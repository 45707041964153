import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../img/logo2.png';

const CustomIcon = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/hall');
  };

  return (
    <div className="custom-icon" onClick={handleClick} style={{ cursor: 'pointer' }}>
      <img src={logo} alt="Logo" style={{ width: '40px'}} />
    </div>
  );
};

export default CustomIcon;
