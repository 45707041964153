import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth } from '../../firebase';
import { useAuth } from '../../hooks/useAuth';
import axios from 'axios';
import Swal from 'sweetalert2';

const SignUp = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, { displayName: name });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users`, {
        uid: user.uid,
        name,
        email,
      });

      setUser({ ...user, ...response.data });

      Swal.fire({
        icon: 'success',
        title: 'Conta criada com sucesso!',
        text: 'Redirecionando para seu painel...',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      }).then(() => {
        navigate('/hall');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    } catch (error) {
      console.error('Erro ao criar conta:', error.message);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Falha ao criar conta. Por favor, tente novamente mais tarde.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="sign-up-page">
      {loading ? (
        <div className="loading">
          <p>Carregando...</p>
        </div>
      ) : (
        <div className="lph">
          <div className="lph-title">
            <h1>Cadastrar</h1>
          </div>
          <form className="login" onSubmit={handleSignUp}>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nome"
              className="login-input"
              required
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail"
              className="login-input"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Senha"
              className="login-input"
              required
            />
            <button type="submit">Cadastrar</button>
          </form>
          <div className="link-su">
            <Link to="/login" className="login-link">
              Ir para Login
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUp;
