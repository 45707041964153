import React, { useState } from 'react';
import axios from 'axios';
import './StandardPrompt.css';

const StandardPrompt = ({ id, image, title, text, onUpdate, onEdit }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [editText, setEditText] = useState(text);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditing(false);
  };

  const handleEdit = async () => {
    setEditing(true);
  };

  const handleSaveEdit = async () => {
    if (!id) {
      console.error('ID do prompt está indefinido.');
      return;
    }
  
    try {
      const updatedPrompt = {
        avatar: image,
        promptName: title,
        promptCommand: editText,
      };
  
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/prompts/${id}`,
        updatedPrompt
      );
  
      console.log('Prompt atualizado com sucesso:', response.data);
  
      onEdit(response.data.prompt);
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao editar prompt:', error.message);
      alert('Erro ao editar prompt. Por favor, tente novamente.');
    }
  };
  
  
  const handleDelete = async () => {
    try {
      if (!id) {
        console.error('ID do prompt está indefinido.');
        return;
      }
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/prompts/${id}`);
      onUpdate();
      setModalOpen(false);
    } catch (error) {
      console.error('Erro ao excluir prompt:', error.message);
      alert('Erro ao excluir prompt.');
    }
  };

  return (
    <>
      <div className="prompt-card" onClick={handleOpenModal}>
        <img src={image} alt={title} className="prompt-image" />
        <h3 className="prompt-title">{title}</h3>
      </div>

      {isModalOpen && (
        <div className="prompt-modal-overlay" onClick={handleCloseModal}>
          <div className="prompt-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal-btn" onClick={handleCloseModal}>X</button>
            <img src={image} alt={title} className="modal-prompt-image" />
            <h3 className="modal-prompt-title">{title}</h3>
            {isEditing ? (
              <textarea
                value={editText}
                onChange={(e) => setEditText(e.target.value)}
                className="modal-prompt-edit"
              />
            ) : (
              <p className="modal-prompt-text">{text}</p>
            )}

            <div className="modal-buttons">
              {isEditing ? (
                <button className="save-button" onClick={handleSaveEdit}>
                  Salvar
                </button>
              ) : (
                <button className="edit-button" onClick={handleEdit}>
                  Editar
                </button>
              )}
              <button className="delete-button" onClick={handleDelete}>
                Excluir
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StandardPrompt;
