// fe-LabComponents/src/admin/IAPanel.jsx
import React, { useState, useEffect } from 'react';
import './IAPanel.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import StandardPrompt from './standardPrompt/StandardPrompt'; 
import avatar1 from './standardPrompt/avatar/avatar1.png';
import avatar2 from './standardPrompt/avatar/avatar2.png';
import avatar3 from './standardPrompt/avatar/avatar3.png';

const IAPanel = ({ isOpen, handleClose, analiseId }) => {
  const [isConfigComplete, setIsConfigComplete] = useState(false);
  const [prompts, setPrompts] = useState([]);
  const [selectedPrompts, setSelectedPrompts] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [useSharedAnalyses, setUseSharedAnalyses] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [hasIaAnalysis, setHasIaAnalysis] = useState(false);
  const [savedMessages, setSavedMessages] = useState([]);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleCloseExportModal = () => setIsExportModalOpen(false);





  // Prompts padrão
  const standardPrompts = [
    {
      image: avatar1,
      title: 'Prompt 1',
      text: 'Este é o primeiro prompt padrão.',
    },
    {
      image: avatar2,
      title: 'Prompt 2',
      text: 'Este é o segundo prompt padrão.',
    },
    {
      image: avatar3,
      title: 'Prompt 3',
      text: 'Este é o terceiro prompt padrão.',
    },
  ];


  // const fetchIaResponse = async () => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/ia-response`);
  //     const iaMessage = response.data.message; // Ajuste conforme o retorno da API
      
  //     // Adiciona a resposta da IA ao chat
  //     setMessages((prev) => [
  //       ...prev,
  //       { message: iaMessage, sender: 'ia', timestamp: new Date() }
  //     ]);
  //   } catch (error) {
  //     console.error('Erro ao buscar resposta da IA:', error);
  //   }
  // };
  

  const fetchIaResponse = async () => {
    try {
      console.log('Chamando a rota respond-last-message...');
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/ia/${analiseId}/respond-last-message`
      );
  
      if (response.data && response.data.message) {
        setMessages((prev) => [
          ...prev,
          { message: response.data.message, sender: 'ia', timestamp: new Date() }, // Use 'message' em vez de 'text'
        ]);
      }
    } catch (error) {
      console.error('Erro ao buscar resposta da IA:', error);
      Swal.fire('Erro!', 'Erro ao buscar resposta da IA.', 'error');
    }
  };
  



  // useEffect(() => {
  //   if (messages.length && messages[messages.length - 1].sender === 'user') {
  //     fetchIaResponse();
  //   }
  // }, [messages]);
  
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/analises/${analiseId}/files`
        );
        setUploadedFiles(response.data);
      } catch (error) {
        Swal.fire('Erro!', 'Erro ao buscar arquivos.', 'error');
      }
    };
    fetchFiles();
    setPrompts(['Resumo', 'Perguntas Frequentes', 'Revisão Técnica']);
  }, [analiseId]);

  const handlePromptSelect = (promptName) => {
    setSelectedPrompts((prev) =>
      prev.includes(promptName)
        ? prev.filter((p) => p !== promptName)
        : [...prev, promptName]
    );
  };

  const handleFileSelect = (file) => {
    setSelectedFiles((prev) =>
      prev.includes(file) ? prev.filter((f) => f !== file) : [...prev, file]
    );
  };


  const handleSendMessage = async () => {
    if (!message.trim()) return;
  
    console.log('Função handleSendMessage foi chamada'); // Log para verificar
  
    setLoading(true);
  
    // Adiciona a mensagem do usuário ao chat imediatamente
    setMessages((prev) => [
      ...prev,
      { message: message, sender: 'user', timestamp: new Date() }, // Use 'message' em vez de 'text'
    ]);
  
    const currentMessage = message; // Salva a mensagem atual para referência
    setMessage(''); // Limpa o campo de entrada
  
    try {
      console.log(
        'Enviando mensagem para:',
        `${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/ia`
      );
      console.log('Mensagem enviada:', currentMessage);
  
      // Envia a mensagem para o backend
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/ia`,
        { message: currentMessage, sender: 'user' }
      );
  
      // Após o envio bem-sucedido, busca a resposta da IA
      await fetchIaResponse(); // Certifique-se de que a resposta da IA será buscada e adicionada ao estado
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
      Swal.fire('Erro!', 'Erro ao enviar mensagem.', 'error');
    } finally {
      setLoading(false);
    }
  };
  

  // const handleSendMessage = async () => {
  //   if (!message.trim()) return;
  
  //   console.log('Função handleSendMessage foi chamada'); // Log para verificar
  
  //   setLoading(true);
  
  //   // Adiciona a mensagem do usuário ao chat imediatamente
  //   setMessages((prev) => [
  //     ...prev,
  //     { text: message, sender: 'user', timestamp: new Date() },
  //   ]);
  
  //   const currentMessage = message; // Salva a mensagem atual para referência
  //   setMessage(''); // Limpa o campo de entrada
  
  //   try {
  //     console.log(
  //       'Enviando mensagem para:',
  //       `${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/ia`
  //     );
  //     console.log('Mensagem enviada:', currentMessage);
  
  //     // Envia a mensagem para o backend
  //     await axios.post(
  //       `${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/ia`,
  //       { message: currentMessage, sender: 'user' }
  //     );
  
  //     // Após o envio bem-sucedido, busca a resposta da IA
  //     // await fetchIaResponse(); // Certifica-se de que a resposta da IA será buscada e adicionada ao estado
  //   } catch (error) {
  //     console.error('Erro ao enviar mensagem:', error);
  //     Swal.fire('Erro!', 'Erro ao enviar mensagem.', 'error');
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  

  // const handleSendMessage = async () => {
  //   if (!message.trim()) return;
  
  //   console.log('Função handleSendMessage foi chamada'); // Log para verificar
  
  //   setLoading(true);
  //   try {
  //     console.log('Enviando mensagem para:', `${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/ia`);
  //     console.log('Mensagem enviada:', message);
  
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/ia`, 
  //       { message, sender: 'user' }
  //     );
  
  //     console.log('Resposta do backend:', response.data);
  
  //     setMessages((prev) => [
  //       ...prev,
  //       { text: message, sender: 'user', timestamp: new Date() },
  //       { text: response.data.message, sender: 'ia', timestamp: new Date() },
  //     ]);
  
  //     setMessage('');
  //   } catch (error) {
  //     console.error('Erro ao enviar mensagem:', error);
  //     Swal.fire('Erro!', 'Erro ao enviar mensagem.', 'error');
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  

  // const handleSendMessage = async () => {
  //   if (!message.trim()) return;
  
  //   setLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/ia`, 
  //       { message, sender: 'user' }
  //     );
  
  //     setMessages((prev) => [
  //       ...prev,
  //       { text: message, sender: 'user', timestamp: new Date() },
  //     ]);
  
  //     setMessage('');
  //   } catch (error) {
  //     console.error('Erro ao enviar mensagem:', error);
  //     Swal.fire('Erro!', 'Erro ao enviar mensagem.', 'error');
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  

  // Carregar mensagens salvas na montagem do componente
  useEffect(() => {
    const fetchSavedMessages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/chat`
        );
        setSavedMessages(response.data);
      } catch (error) {
        // Swal.fire('Erro!', 'Erro ao carregar mensagens salvas.', 'error');
      }
    };

    fetchSavedMessages();
  }, [analiseId]);


  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/prompts`);
        console.log('Prompts recebidos:', response.data); // Verifica o retorno da API no console
  
        const availablePrompts = response.data.filter(
          (prompt) => prompt.promptName !== 'Resumo' && prompt.promptName !== 'Perguntas Frequentes'
        );
  
        setPrompts(availablePrompts); // Armazena apenas os prompts válidos
      } catch (error) {
        console.error('Erro ao buscar prompts:', error);
        Swal.fire('Erro!', 'Erro ao buscar prompts.', 'error');
      }
    };
  
    fetchPrompts();
  }, []);
  
  

  const handleOpenExportModal = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/saved-messages`);
      setSavedMessages(response.data);
      setIsExportModalOpen(true);
    } catch (error) {
      console.error('Erro ao buscar mensagens salvas:', error);
    }
  };
  const handleExportMessage = async (msg) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/export-message`,
        { messageId: msg._id },
        { responseType: 'blob' }
      );
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `message-${msg._id}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      Swal.fire('Erro!', 'Erro ao exportar a mensagem.', 'error');
    }
  };
  
  
  
  const handleDeleteMessage = async (messageId) => {
    try {
      // Encontrar o índice da mensagem par correspondente
      const messageIndex = savedMessages.findIndex((msg) => msg._id === messageId);
  
      if (messageIndex === -1 || messageIndex % 2 !== 0) {
        Swal.fire('Erro!', 'Mensagem não encontrada ou não é um índice par.', 'error');
        return;
      }
  
      // Identificar as duas mensagens (par e a próxima ímpar) para exclusão
      const messageIdsToDelete = [savedMessages[messageIndex]._id];
  
      if (savedMessages[messageIndex + 1]) {
        messageIdsToDelete.push(savedMessages[messageIndex + 1]._id);
      }
  
      // Fazer uma requisição DELETE para cada mensagem encontrada
      await Promise.all(
        messageIdsToDelete.map((id) =>
          axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/saved-messages/${id}`)
        )
      );
  
      // Atualizar o estado local, removendo as duas mensagens
      setSavedMessages((prevMessages) =>
        prevMessages.filter((msg) => !messageIdsToDelete.includes(msg._id))
      );
  
      Swal.fire('Sucesso!', 'Mensagens excluídas com sucesso.', 'success');
    } catch (error) {
      console.error('Erro ao excluir mensagens:', error);
      Swal.fire('Erro!', 'Erro ao excluir as mensagens.', 'error');
    }
  };
  


  if (!isOpen) return null;



    const handleConfigComplete = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/ia-base`,
          {
            prompts: selectedPrompts,
            selectedFiles,
            useSharedAnalyses,
          }
        );
    
        console.log('Configuração salva:', response.data);
    
        // Limpar o chat no frontend
        setMessages([]);
        setIsConfigComplete(true);
        setIsChatOpen(true);
      } catch (error) {
        console.error('Erro ao salvar configuração da IA:', error);
        Swal.fire('Erro!', 'Erro ao salvar a configuração da IA.', 'error');
      }
    };
  
  
  
  const handleSaveAnalysis = async () => {
    if (isSaving) return; // Evita duplicação de chamadas
  
    setIsSaving(true); // Desabilita o botão
  
    try {
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/save`,
        {
          technicalAnalysisReport: 'Conteúdo do relatório...',
          savedMessages: messages.map((msg) => ({
            sender: msg.sender,
            message: msg.text || msg.message || '',
            timestamp: msg.timestamp,
          })),
        }
      );
  
      Swal.fire('Sucesso!', 'Análise e mensagens salvas com sucesso.', 'success');
    } catch (error) {
      console.error('Erro ao salvar análise:', error);
      Swal.fire('Erro!', 'Erro ao salvar a análise.', 'error');
    } finally {
      setIsSaving(false); // Reabilita o botão após a requisição
    }
  };
  
  
  
  const handleExportAnalysis = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/export`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `analysis-${analiseId}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      Swal.fire('Erro!', 'Erro ao exportar a análise.', 'error');
    }
  };
  
  const handleNewAnalysis = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/ia-analysis/${analiseId}/new`);
      setMessages([]);
      setSelectedPrompts([]);
      setSelectedFiles([]);
      setUseSharedAnalyses(false);
      setIsConfigComplete(false);
      setIsChatOpen(false);
      
      Swal.fire('Nova Análise', 'Nova análise iniciada.', 'info');
    } catch (error) {
      Swal.fire('Erro!', 'Erro ao iniciar nova análise.', 'error');
    }
  };
  

  


  // Combine prompts padrão e prompts do backend
const combinedPrompts = [
  ...standardPrompts.map(prompt => ({
    promptName: prompt.title,
    promptCommand: prompt.text,
    avatar: prompt.image,
  })),
  ...prompts, // Prompts recebidos do backend
];
  

  if (!isOpen) return null;

  return (
    <div className="ia-panel-overlay" onClick={handleClose}>
      <div className="ia-panel-modal" onClick={(e) => e.stopPropagation()}>
        <h2>Painel de Inteligência Artificial</h2>
        <button className="close-button" onClick={handleClose}>X</button>

{/* ***************************************************** */}

          {!isConfigComplete && (
            <div className="config-ia-container">
              <h3>Configuração da Base da IA</h3>

              <div className="config-ia-prompt-selection">
                <h4>Escolha Prompts Predefinidos</h4>
                {combinedPrompts.length === 0 ? (
                  <p>Nenhum prompt disponível.</p>
                ) : (
                  combinedPrompts.map((prompt, index) => (
                    <div key={index} className="config-ia-prompt-item">
                      <input
                        type="checkbox"
                        checked={selectedPrompts.includes(prompt.promptName)}
                        onChange={() => handlePromptSelect(prompt.promptName)}
                      />
                      <img
                        src={prompt.avatar}
                        alt={`${prompt.promptName} avatar`}
                        className="config-ia-avatar"
                      />
                      <span>{prompt.promptName}</span>
                    </div>
                  ))
                )}
              </div>

              {uploadedFiles.length > 0 && (
                <div className="config-ia-file-selection">
                  <h4>Escolha Arquivos Relacionados</h4>
                  {uploadedFiles.map((file) => (
                    <div key={file._id} className="config-ia-file-item">
                      <input
                        type="checkbox"
                        checked={selectedFiles.includes(file)}
                        onChange={() => handleFileSelect(file)}
                      />
                      <span>{file.fileName}</span>
                    </div>
                  ))}
                </div>
              )}

              <div className="config-ia-shared-analyses">
                <h4>Usar Análises Compartilhadas?</h4>
                <input
                  type="checkbox"
                  checked={useSharedAnalyses}
                  onChange={(e) => setUseSharedAnalyses(e.target.checked)}
                />
              </div>

              <button className="config-ia-button" onClick={handleConfigComplete}>
                Iniciar Chat
              </button>
            </div>
          )}


  {/*********************************************/}


  {isChatOpen && (
  <div className="ia-chat-container">
    <h3 className="ia-chat-title">Chat com a IA</h3>

    <div className="ia-chat-messages">
  {messages.map((msg, index) => (
    <div
      key={index}
      className={`ia-message ${msg.sender}`}
      onClick={() => handleExportMessage(msg)}
    >
      <span className="ia-message-text">{msg.message || 'Mensagem não disponível'}</span> {/* Verifique se 'message' está presente */}
      <span className="ia-message-timestamp">
        {new Date(msg.timestamp).toLocaleTimeString()}
      </span>
    </div>
  ))}
</div>

    <div className="ia-chat-input">
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Escreva sua mensagem..."
        rows="2"
      />
      <button onClick={handleSendMessage} disabled={loading || !message.trim()}>
        {loading ? 'Enviando...' : 'Enviar'}
      </button>
    </div>

    <div className="ia-chat-buttons">
      <button onClick={handleSaveAnalysis} disabled={isSaving}>
        {isSaving ? 'Salvando...' : 'Salvar Análise'}
      </button>
      <button onClick={handleOpenExportModal}>Exportar Mensagens Salvas</button>
      <button onClick={handleNewAnalysis}>Gerar Nova Análise</button>
    </div>
  </div>
)}




 {/*********************************************/}


  
        {/* Modal de Exportação */}
        {isExportModalOpen && (
  <div className="export-modal-overlay" onClick={handleCloseExportModal}>
    <div className="export-modal" onClick={(e) => e.stopPropagation()}>
      <h3>Mensagens Salvas</h3>
      {savedMessages.length === 0 ? (
        <p>Nenhuma mensagem salva.</p>
      ) : (
        savedMessages
          .filter((_, index) => index % 2 === 0) // Exibe apenas mensagens de índice par
          .map((msg) => (
            <div key={msg._id} className="message-item">
              <div className="message-content">
                <p>{msg.message}</p>
                <span>{new Date(msg.timestamp).toLocaleString()}</span>
              </div>
              <div className="message-actions">
                {/* Botão de Exportar */}
                <button 
                  onClick={() => handleExportMessage(msg)} 
                  className="export-button"
                  title="Exportar"
                >
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.25589 16C3.8899 15.0291 3 13.4422 3 11.6493C3 9.20008 4.8 6.9375 7.5 6.5C8.34694 4.48637 10.3514 3 12.6893 3C15.684 3 18.1317 5.32251 18.3 8.25C19.8893 8.94488 21 10.6503 21 12.4969C21 14.0582 20.206 15.4339 19 16.2417M12 21V11M12 21L9 18M12 21L15 18"
                      stroke="#000000"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                {/* Botão de Excluir */}
                <button 
                  onClick={() => handleDeleteMessage(msg._id)} 
                  className="delete-button"
                  title="Excluir"
                >
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 15L9 13M9 13L11 11M9 13H13C14.1046 13 15 13.8954 15 15V16M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M4 6H20M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6"
                      stroke="#000000"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ))
      )}
      <button onClick={handleCloseExportModal} className="close-modal-button">
        Fechar
      </button>
    </div>
  </div>
)}


      </div>
    </div>
  );
  
  
  
};

export default IAPanel;
