// src/App.js
import React from 'react';
import AppRoutes from './routes/AppRoutes';
import './App.css';

const App = () => {
  return (
    <>
      <AppRoutes />
    </>
  );
};

export default App;
