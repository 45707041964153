import React, { useEffect, useState } from 'react';
import './Cards.css';
import './Hall.css';
import logo from '../img/logo.png';
import iconStruct from '../img/instrucao.png'

const Hall = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const navigate = (path) => {
    window.location.href = path; // Navegação simples sem hooks
  };

  useEffect(() => {
    document.body.style.background = 'none';
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.background = '';
      document.body.style.overflow = '';
    };
  }, []);

  const handleOverlayClick = (e) => {
    if (e.target.className === 'modal-overlay') {
      setModalOpen(false);
    }
  };

  return (
    <div className="hall-container">
      <div className="welcome-message">
      <img src={logo} alt="LabConect Logo" className="welcome-logo" />
        {/* <h1>Bem-vindo ao LabConect!</h1> */}
        <p>Acompanhe suas análises, solicite novas ou converse com nossos especialistas.</p>
      </div>

      <main className="cards">
        <section className="card contact">
          <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="url(#gradPurple)">
              <defs>
                <linearGradient id="gradPurple" x1="0%" y1="0%" x2="100%">
                  <stop offset="0%" style={{ stopColor: '#053155', stopOpacity: 1 }} />
                  <stop offset="100%" style={{ stopColor: '#053155', stopOpacity: 1 }} />
                </linearGradient>
              </defs>
              <path d="M16.707 2.293A.996.996 0 0 0 16 2H8c-.414 0-.785.255-.934.641l-5 13a.999.999 0 0 0 .227 1.066l5 5A.996.996 0 0 0 8 22h8c.414 0 .785-.255.934-.641l5-13a.999.999 0 0 0-.227-1.066l-5-5zM18.585 7h-5.171l-3-3h5.172l2.999 3zM8.381 4.795l3.438 3.438-4.462 10.71-3.19-3.191L8.381 4.795zM15.313 20h-6.23l4.583-11h5.878l-4.231 11z"></path>
            </svg>
          </div>
          <h3>Minhas Análises</h3>
          <span>Veja todas as suas análises realizadas e seus resultados detalhados.</span>
          <button onClick={() => navigate('/minhas-analises')}>Acessar</button>
        </section>

        <section className="card shop">
          <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="url(#gradBlue)">
              <defs>
                <linearGradient id="gradBlue" x1="0%" y1="0%" x2="100%">
                  <stop offset="0%" style={{ stopColor: '#053155', stopOpacity: 1 }} />
                  <stop offset="100%" style={{ stopColor: '#053155', stopOpacity: 1 }} />
                </linearGradient>
              </defs>
              <path d="m20.895 7.553-2-4A1.001 1.001 0 0 0 18 3h-5c-.379 0-.725.214-.895.553L10.382 7H6c-.379 0-.725.214-.895.553l-2 4a1 1 0 0 0 0 .895l2 4c.17.338.516.552.895.552h4.382l1.724 3.447A.998.998 0 0 0 13 21h5c.379 0 .725-.214.895-.553l2-4a1 1 0 0 0 0-.895L19.118 12l1.776-3.553a1 1 0 0 0 .001-.894zM13.618 5h3.764l1.5 3-1.5 3h-3.764l-1.5-3 1.5-3zm-8.5 7 1.5-3h3.764l1.5 3-1.5 3H6.618l-1.5-3zm12.264 7h-3.764l-1.5-3 1.5-3h3.764l1.5 3-1.5 3z"></path>
            </svg>
          </div>
          <h3>Solicitar Análise </h3>
          <span>Solicite uma nova análise personalizada com nossos especialistas.</span>
          <button onClick={() => navigate('/nova-analise')}>Acessar</button>
        </section>

        <section className="card about">
          <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="url(#gradPink)">
              <defs>
                <linearGradient id="gradPink" x1="0%" y1="0%" x2="100%">
                  <stop offset="0%" style={{ stopColor: '#053155', stopOpacity: 1 }} />
                  <stop offset="100%" style={{ stopColor: '#053155', stopOpacity: 1 }} />
                </linearGradient>
              </defs>
              <path d="M20 2H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h3v3.766L13.277 18H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm0 14h-7.277L9 18.234V16H4V4h16v12z"></path>
              <circle cx="15" cy="10" r="2"></circle>
              <circle cx="9" cy="10" r="2"></circle>
            </svg>
          </div>
          <h3>Falar com um especialista</h3>
          <span>Converse com um de nossos especialistas para tirar suas dúvidas.</span>
          <button onClick={() => navigate('/chat')}>Acessar</button>
        </section>
      </main>

      <button className="floating-button" onClick={() => setModalOpen(true)}>
        <img src={iconStruct} alt="Instruções" className="icon-image" />
        Instruções
      </button>

      {isModalOpen && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal-content">
            <h2>Instruções</h2>
            <ul>
              <li>Acessar suas análises realizadas.</li>
              <li>Solicitar novas análises personalizadas.</li>
              <li>Conversar com nossos especialistas.</li>
              <li>As análises serão analisadas na ordem em que são enviadas.</li>
              <li>Qualquer dúvida deve ser tratada no chat geral do sistema, na opção "Falar com um especialista".</li>
              <li>Analises relacionadas devem ser pertencer a um mesmo grupo de analises</li>
            </ul>
            <button className="close-button" onClick={() => setModalOpen(false)}>
              Fechar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hall;
