import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './AnaliseFull.css';

const AnaliseFull = () => {
  const { id } = useParams(); 
  const [analise, setAnalise] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false); 
  const [tituloInformacao, setTituloInformacao] = useState('');
  const [novaInformacao, setNovaInformacao] = useState('');
  const [formError, setFormError] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [mensagem, setMensagem] = useState('');

  useEffect(() => {
    const fetchAnalise = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${id}`);
        setAnalise(response.data);
        setLoading(false);
      } catch (error) {
        setError('Erro ao carregar a análise.');
        setLoading(false);
      }
    };

    const fetchChat = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${id}/chat`);
        setChatMessages(response.data);
      } catch (error) {
        console.error('Erro ao carregar o chat:', error);
      }
    };

    fetchAnalise();
    fetchChat();
  }, [id]);
  
  const handleAddInfo = async () => {
    if (!tituloInformacao || !novaInformacao) {
      setFormError('Preencha todos os campos.');
      return;
    }

    try {
      const newInfo = { title: tituloInformacao, content: novaInformacao };

      
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${id}/add-info`, newInfo);

      
      setAnalise(prevAnalise => ({
        ...prevAnalise,
        analysisData: prevAnalise.analysisData.map(data => ({
          ...data,
          additionalInfo: [...(data.additionalInfo || []), newInfo],
        })),
      }));

      setTituloInformacao('');
      setNovaInformacao('');
      setShowModal(false);
      setFormError('');
    } catch (error) {
      console.error('Erro ao adicionar a nova informação:', error);
      setFormError('Erro ao adicionar a informação.');
    }
  };

  const handleCloseChatModal = (e) => {
    if (e.target.className === 'chat-modal-overlay') {
      setShowChatModal(false);
    }
  };
  const handleSendMessage = async () => {
    if (mensagem.trim() === '') return; 
  
    const newMessage = {
      sender: 'solicitante', 
      message: mensagem,
    };
  
    try {
      
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${id}/chat`, newMessage);
      setChatMessages(response.data);
      setMensagem('');
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    }
  };
  
  if (loading) {
    return <div className="loading">Carregando...</div>;
  }
  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="analise-full-container">
        <div className='all-analises'>

      {analise ? (
        <>
          {/* Parte 1: Imagem e título */}
          <div className="informacoes-adicionais">
            <div className="analise-info">
            <h1>{analise.title}</h1>
              <div className="analise-header">
                {analise.analysisData[0].samplePhoto && (
                  <img 
                    className="analise-image" 
                    src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${analise.analysisData[0].samplePhoto}`} 
                    alt="Foto da Amostra" 
                  />
                )}
                 {/* Título da análise */}
              </div>
              <p><strong>Tipo de Amostra:</strong> {analise.analysisData[0].sampleType}</p>
              <p><strong>Responsável:</strong> {analise.analysisData[0].responsibleName}</p>
              <p><strong>Data de Coleta:</strong> {new Date(analise.analysisData[0].collectionDate).toLocaleDateString()}</p>
              <p><strong>Geolocalização:</strong> {analise.analysisData[0].geolocation}</p>
              <p><strong>Armazenamento:</strong> {analise.analysisData[0].storageType}</p>
              <p><strong>Observações:</strong> {analise.analysisData[0].additionalObservations}</p>
              <p><strong>Aplicação do Resultado:</strong> {analise.analysisData[0].analysisApplication}</p>
            </div>
          </div>

          {/* Parte 3: Informações adicionais */}
          <div className="outras-informacoes">
            <h2>Informações adicionais</h2>

            <button className="add-info-btn" onClick={() => setShowModal(true)}>Adicionar informações</button>

            {analise.analysisData[0].additionalInfo && analise.analysisData[0].additionalInfo.length > 0 ? (
              analise.analysisData[0].additionalInfo.map((info, index) => (
                <div key={index}>
                  <h3>{info.title}</h3>
                  <p>{info.content}</p>
                </div>
              ))
            ) : (
              <p>Nenhuma informação adicional.</p>
            )}

          </div>

          {/* Modal de Adicionar Informações */}
          {showModal && (
            <div className="modal">
                <h3 className="modal-title">Adicionar Informação</h3>
                <div className="modal-form">
                  <div className="form-group">
                    <label htmlFor="tituloInformacao">Título</label>
                    <input
                      type="text"
                      id="tituloInformacao"
                      placeholder="Título da nova informação"
                      value={tituloInformacao}
                      onChange={(e) => setTituloInformacao(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="novaInformacao">Informação</label>
                    <textarea
                      id="novaInformacao"
                      placeholder="Nova Informação"
                      value={novaInformacao}
                      onChange={(e) => setNovaInformacao(e.target.value)}
                    />
                  </div>
                  {formError && <p className="error-message">{formError}</p>}
                </div>
                <div className="modal-buttons">
                  <button className="btn btn-save" onClick={handleAddInfo}>Salvar</button>
                  <button className="btn btn-cancel" onClick={() => setShowModal(false)}>Cancelar</button>
                </div>
              </div>

          )}

          {/* Modal de Chat Direto */}
          {showChatModal && (
            <div className="chat-modal-overlay" onClick={handleCloseChatModal}>
              
              <div className="chat-modal">
                <div className='chat-title'>
                    <h2>Chat Direto: {analise.title}</h2>
                </div>
                <div className="chat-body">
                    {Array.isArray(chatMessages) && chatMessages.length > 0 ? (
                      chatMessages.map((msg, index) => (
                        <div key={index} className={`chat-message ${msg.sender}`}>
                          <p>{msg.message}</p>
                          {/* Formatação correta da data */}
                          <span className="chat-timestamp">
                            {new Date(msg.timestamp).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'short' })}
                          </span>
                        </div>
                      ))
                    ) : (
                      <p>Nenhuma mensagem ainda.</p>
                    )}
                  </div>
                <div className="chat-footer">
                  <input 
                    type="text" 
                    placeholder="Digite sua mensagem" 
                    value={mensagem} 
                    onChange={(e) => setMensagem(e.target.value)} 
                  />
                  <button onClick={handleSendMessage}>Enviar</button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="error">Análise não encontrada.</div>
      )}
</div>
      {/* SVG fixado na parte inferior direita */}
      <div className="fixed-svg" onClick={() => setShowChatModal(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" style={{ fill: '#043057' }}>
          <path d="M16 14h.5c.827 0 1.5-.673 1.5-1.5v-9c0-.827-.673-1.5-1.5-1.5h-13C2.673 2 2 2.673 2 3.5V18l5.333-4H16zm-9.333-2L4 14V4h12v8H6.667z"></path>
          <path d="M20.5 8H20v6.001c0 1.1-.893 1.993-1.99 1.999H8v.5c0 .827.673 1.5 1.5 1.5h7.167L22 22V9.5c0-.827-.673-1.5-1.5-1.5z"></path>
        </svg>
      </div>
    </div>
  );
};

export default AnaliseFull;
