  import React, { useEffect, useState } from 'react';
  import { useNavigate } from 'react-router-dom'; // Para redirecionamento
  import axios from 'axios';
  import StandardPrompt from './standardPrompt/StandardPrompt';
  import Prompt from './Prompt';
  import avatar1 from './standardPrompt/avatar/avatar1.png';
  import avatar2 from './standardPrompt/avatar/avatar2.png';
  import avatar3 from './standardPrompt/avatar/avatar3.png';
  import logo from '../img/logo.png';
  import './AdminPanel.css';

  const AdminPanel = () => {
    const [analyses, setAnalyses] = useState([]);
    const [filteredAnalyses, setFilteredAnalyses] = useState([]);
    const [sampleType, setSampleType] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [dateFilter, setDateFilter] = useState('');
    const [showCreatePromptModal, setShowCreatePromptModal] = useState(false);
    const [backendPrompts, setBackendPrompts] = useState([]);
    const navigate = useNavigate(); // Hook para navegação

    const standardPrompts = [
      { image: avatar1, title: 'Prompt 1 Padrão', text: 'Este é um prompt não editável.' },
      { image: avatar2, title: 'Prompt 2 Padrão', text: 'Este é um prompt não editável.' },
      { image: avatar3, title: 'Prompt 3 Padrão', text: 'Este é um prompt não editável.' },
    ];

    useEffect(() => {
      const fetchAnalyses = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analises`);
          setAnalyses(response.data);
          setFilteredAnalyses(response.data);
        } catch (error) {
          console.error('Erro ao buscar análises:', error);
        }
      };
      fetchAnalyses();
    }, []);

    useEffect(() => {
      const fetchPrompts = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/prompts`);
          setBackendPrompts(response.data);
        } catch (error) {
          console.error("Erro ao buscar prompts:", error);
        }
      };
      fetchPrompts();
    }, []);

    const handleSavePrompt = async (newPrompt) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/prompts`, newPrompt);
        setBackendPrompts((prevPrompts) => [...prevPrompts, response.data]);
        setShowCreatePromptModal(false); // Fecha o modal após salvar
      } catch (error) {
        console.error("Erro ao salvar prompt:", error);
      }
    };

    const handleClearFilters = () => {
      setSampleType('');
      setSearchTerm('');
      setStatusFilter('');
      setDateFilter('');
      setFilteredAnalyses(analyses);
    };

    const handleOpenAnalysis = (analiseId) => {
      navigate(`/admin/analise-full/${analiseId}`);
    };

    const filterAnalyses = () => {
      let filtered = analyses;

      if (sampleType) {
        filtered = filtered.filter((analysis) =>
          analysis.analysisData[0].sampleType.toLowerCase() === sampleType.toLowerCase()
        );
      }

      if (statusFilter) {
        filtered = filtered.filter((analysis) =>
          analysis.analysisData[0].status.toLowerCase() === statusFilter.toLowerCase()
        );
      }

      if (searchTerm) {
        filtered = filtered.filter((analysis) =>
          analysis.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          analysis.analysisData[0].responsibleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          analysis.analysisData[0].sampleIdentification.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      if (dateFilter) {
        filtered = filtered.filter(
          (analise) =>
            new Date(analise.analysisData[0].collectionDate).toLocaleDateString() ===
            new Date(dateFilter).toLocaleDateString()
        );
      }

      setFilteredAnalyses(filtered);
    };

    const handleEditPrompt = (updatedPrompt) => {
      setBackendPrompts((prevPrompts) =>
        prevPrompts.map((prompt) =>
          prompt._id === updatedPrompt._id ? updatedPrompt : prompt
        )
      );
    };

    useEffect(() => {
      filterAnalyses();
    }, [sampleType, searchTerm, statusFilter, dateFilter, analyses]);

    return (
      <section className="admin-panel-container">
        <div className="admin-panel">
          <div className="admin-panel-header">
            <img src={logo} alt="Logo" />
            <h1>Painel do Administrador</h1>
            <p>Gerencie análises e relatórios com facilidade</p>
          </div>


                  {/* Prompt Section */}
                  <div className="prompts-section">
            <h3>Prompts</h3>
            <div className="prompts-container">
              {/* Prompts padrão */}
              {standardPrompts.map((prompt, index) => (
                <StandardPrompt
                  key={index}
                  image={prompt.image}
                  title={prompt.title}
                  text={prompt.text}
                />
              ))}

              {/* Prompts do backend */}
              {backendPrompts.map((prompt) => (
                <StandardPrompt
                  key={prompt._id}
                  id={prompt._id}
                  image={prompt.avatar}
                  title={prompt.promptName}
                  text={prompt.promptCommand}
                  onEdit={handleEditPrompt}
                  onUpdate={() =>
                    setBackendPrompts((prev) => prev.filter((p) => p._id !== prompt._id))
                  }
                />
              ))}
            </div>

            {/* Botão para criar novo prompt */}
            <button className="open-modal-btn" onClick={() => setShowCreatePromptModal(true)}>
              Criar Novo Prompt
            </button>

            {/* Modal de criação de prompt */}
            <Prompt
              isOpen={showCreatePromptModal}
              onClose={() => setShowCreatePromptModal(false)}
              onSave={handleSavePrompt}
            />
          </div>

          <div className="filter-section">
            <h2>Filtros de Pesquisa</h2>
            <div className="filter-bar">
              <input
                type="text"
                placeholder="Buscar por nome ou palavra-chave..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <select value={sampleType} onChange={(e) => setSampleType(e.target.value)}>
                <option value="">Todos os Tipos</option>
                <option value="agua">Água</option>
                <option value="solo">Solo</option>
              </select>
              <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                <option value="">Todos os Status</option>
                <option value="em espera">Em Espera</option>
                <option value="em andamento">Em Andamento</option>
                <option value="concluída">Concluída</option>
              </select>
              <input
                type="date"
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
              />
              <button onClick={handleClearFilters}>Limpar Filtros</button>
            </div>
          </div>

          <div className="analyses-list">
            {filteredAnalyses.length > 0 ? (
              filteredAnalyses.map((analise) => (
                <div
                  key={analise._id}
                  className="analise-card"
                  onClick={() => handleOpenAnalysis(analise._id)}
                >
                  <h3>{analise.title}</h3>
                  <div className="analise-header">
                    {analise.analysisData[0].samplePhoto && (
                      <img
                        className="analise-image"
                        src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${analise.analysisData[0].samplePhoto}`}
                        alt="Foto da Amostra"
                      />
                    )}
                  </div>
                  <p>
                    <strong>Tipo:</strong> {analise.analysisData[0].sampleType}
                  </p>
                  <p>
                    <strong>Responsável:</strong> {analise.analysisData[0].responsibleName}
                  </p>
                </div>
              ))
            ) : (
              <p>Nenhuma análise encontrada</p>
            )}
          </div>
        </div>
      </section>
    );
  };

  export default AdminPanel;

