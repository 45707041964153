import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import './MinhasAnalises.css';

const MinhasAnalises = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showUnitaryModal, setShowUnitaryModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);

  useEffect(() => {
    const hasReloaded = sessionStorage.getItem('minhasAnalisesReloaded');

    if (!hasReloaded) {
      sessionStorage.setItem('minhasAnalisesReloaded', 'true');
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (user) {
      setLoading(false);
    } else {
      setError('Usuário não autenticado.');
      setLoading(false);
    }
  }, [user]);

  const handleCardClick = (analiseId) => {
    window.location.href = `/analise-full/${analiseId}`;
  };

  if (loading) {
    return <div className="loading">Carregando...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  const unitaryAnalyses =
    user.analyses?.filter((analise) => analise.analysisData[0]?.identifier === 0) || [];

  const groupedAnalyses = user.groups.map((group) => {
    const groupAnalyses = user.analyses.filter(
      (analise) => analise.analysisData[0]?.identifier === group.identifier
    );
    return { groupName: group.name, analyses: groupAnalyses };
  });

  return (
    <div className="analises-container">
      <h1>Minhas Análises</h1>
      <div className="button-group">
        <button
          className="modal-button"
          onClick={() => setShowUnitaryModal(true)}
        >
          Ver Análises Avulsas
        </button>
        <button
          className="modal-button"
          onClick={() => setShowGroupModal(true)}
        >
          Ver Grupos de Análises
        </button>
      </div>

      {/* Modal para Análises Avulsas */}
      {showUnitaryModal && (
        <div className="modal-overlay" onClick={() => setShowUnitaryModal(false)}>
          <div className="modal-content">
            <h2>Análises Avulsas</h2>
            <div className="analises-list">
              {unitaryAnalyses.length > 0 ? (
                unitaryAnalyses.map((analise, index) => (
                  <div
                    key={index}
                    className="analise-card"
                    onClick={() => handleCardClick(analise._id)}
                  >
                    <h3>{analise.title}</h3>
                    <div className="analise-header">
                      {analise.analysisData[0].samplePhoto && (
                        <img
                          className="analise-image"
                          src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${analise.analysisData[0].samplePhoto}`}
                          alt="Foto da Amostra"
                        />
                      )}
                    </div>
                    <p><strong>Tipo de Amostra:</strong> {analise.analysisData[0].sampleType}</p>
                    <p><strong>Responsável:</strong> {analise.analysisData[0].responsibleName}</p>
                    <p><strong>Data de Coleta:</strong> {new Date(analise.analysisData[0].collectionDate).toLocaleDateString()}</p>
                  </div>
                ))
              ) : (
                <p>Você ainda não tem análises avulsas.</p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Modal para Grupos de Análises */}
      {showGroupModal && (
        <div className="modal-overlay" onClick={() => setShowGroupModal(false)}>
          <div className="modal-content">
            <h2>Grupos de Análises</h2>
            {groupedAnalyses.length > 0 ? (
              groupedAnalyses.map((group, index) => (
                <div key={index} className="group">
                  <h3>{group.groupName}</h3>
                  <div className="analises-list">
                    {group.analyses.length > 0 ? (
                      group.analyses.map((analise, i) => (
                        <div
                          key={i}
                          className="analise-card"
                          onClick={() => handleCardClick(analise._id)}
                        >
                          <h3>{analise.title}</h3>
                          <div className="analise-header">
                {analise.analysisData[0].samplePhoto && (
                  <img 
                    className="analise-image" 
                    src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${analise.analysisData[0].samplePhoto}`} 
                    alt="Foto da Amostra" 
                  />
                )}
                 {/* Título da análise */}
              </div>
                          <p><strong>Tipo de Amostra:</strong> {analise.analysisData[0]?.sampleType || 'N/A'}</p>
                          <p><strong>Responsável:</strong> {analise.analysisData[0]?.responsibleName || 'N/A'}</p>
                          <p><strong>Data de Coleta:</strong> {analise.analysisData[0]?.collectionDate ? new Date(analise.analysisData[0].collectionDate).toLocaleDateString() : 'N/A'}</p>
                        </div>
                      ))
                    ) : (
                      <p>Sem análises neste grupo.</p>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>Ainda não há nenhum grupo de análises.</p>
            )}
          </div>
        </div>
      )}

      {/* Botão de Nova Análise */}
      <button
        className="nova-analise-btn"
        onClick={() => (window.location.href = '/nova-analise')}
      >
        Solicitar Nova Análise
      </button>
    </div>
  );
};

export default MinhasAnalises;
