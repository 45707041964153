import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import Swal from 'sweetalert2';
import { useAuth } from '../../hooks/useAuth';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      Swal.fire('Erro', 'Formato de e-mail inválido.', 'error');
      return;
    }
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      setUser(user);
      setLoading(false);
      Swal.fire('Sucesso', 'Login realizado com sucesso!', 'success');
      navigate('/hall');
    } catch (error) {
      setLoading(false);
      if (error.code === 'auth/invalid-credential' || error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        Swal.fire('Erro', 'Usuário ou senha inválidos.', 'error');
      } else {
        Swal.fire('Erro', error.message, 'error');
      }
    }
  };

  return (
    <div className="login-page">
      {loading ? (
        <div className="loading">
          <p>Carregando...</p>
        </div>
      ) : (
        <div className="lph">
          <div className="lph-title">
            <h1>Login</h1>
          </div>
          <form className="login" onSubmit={handleLogin}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail"
              className="login-input"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Senha"
              className="login-input"
            />
            <button type="submit">Login</button>
          </form>
          <div className="login-links">
            <Link to="/reset-password" className="login-link">Esqueceu a senha?</Link>
            <br />
            <Link to="/sign-up" className="login-link">Criar nova conta</Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
