import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import axios from 'axios';
import './Chat.css';

const Chat = () => {
  const { user } = useAuth();  // Removido updateUser, já que não está sendo utilizado corretamente
  const [novaMensagem, setNovaMensagem] = useState('');
  const [loading, setLoading] = useState(false);

  // Estado das mensagens
  const [mensagens, setMensagens] = useState(user?.chat || []);

  const handleSend = async (e) => {
    e.preventDefault();
    if (!novaMensagem.trim()) return;

    const novaMsg = {
      sender: 'solicitante',
      message: novaMensagem,
      timestamp: new Date(),
    };

    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/${user.uid}/chat`, novaMsg);
      
      // Atualiza o chat localmente
      const updatedMessages = [...mensagens, response.data];
      setMensagens(updatedMessages);

      // Limpa o campo de mensagem
      setNovaMensagem('');
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="chat-container">
      <h1>Falar com um Especialista</h1>

      <div className="chat-box">
        {mensagens.length === 0 ? (
          <p>Não há mensagens. Inicie uma conversa com um especialista!</p>
        ) : (
          mensagens.map((msg, index) => (
            <div key={index} className={`mensagem ${msg.sender === 'solicitante' ? 'user' : 'especialista'}`}>
              <p>{msg.message}</p>
              <span>{new Date(msg.timestamp).toLocaleTimeString()}</span>
            </div>
          ))
        )}
      </div>

      <form onSubmit={handleSend} className="chat-form">
        <input
          type="text"
          value={novaMensagem}
          onChange={(e) => setNovaMensagem(e.target.value)}  // Atualiza o valor do input
          placeholder="Digite sua mensagem"
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Enviando...' : 'Enviar'}
        </button>
      </form>
    </div>
  );
};

export default Chat;
